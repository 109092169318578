





































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import SearchBar from '@/commoncomponents/SearchBar.vue';
import { ScreenText } from '@/lang/ScreenText';
import { PanelState, PANEL_CLOSED, PANEL_OPEN } from './types';

const screenText = new ScreenText();

@Component({
  components: {
    SearchBar
  }
})
export default class ExpandableSelectList extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: Array, required: true }) readonly items!: Array<unknown>;
  @Prop({ type: String, default: 'id' }) readonly keyField!: string;
  @Prop({ type: String, default: '' }) readonly subtitle!: string;
  @Prop({ type: Number, default: 300 }) readonly listMaxHeight!: number;
  @Prop({ type: String, default: screenText.getScreenText('NO_ITEMS_RESULTS') }) readonly noItemsText!: string;
  @Prop({ type: Array, default: () => [] }) readonly excludedSearchFields!: Array<string>;
  @Prop({ type: Boolean, default: false }) readonly clearSearchOnClose!: boolean;
  @PropSync('selectedItem', { required: true }) selectedItemSync!: unknown | null;
  @PropSync('isPanelOpen', { required: true }) isPanelOpenSync!: boolean;
  @Ref('searchBarRef') readonly searchBarRef!: SearchBar;

  panel: PanelState = null;
  searchText = '';

  get titleHtmlAttribute(): string {
    return this.title.toLocaleLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '');
  }

  get itemsToShow(): Array<unknown> {
    let itemsToShow = this.items;
    if (this.searchText) {
      itemsToShow = this.items.filter(item => {
        const stringified = this.getItemStringValue(item);
        return stringified.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
      });
    }
    return itemsToShow;
  }

  created() {
    if (this.isPanelOpenSync) {
      this.panel = PANEL_OPEN;
    }
  }

  @Watch('isPanelOpenSync')
  onIsPanelOpenSyncChange(value: boolean) {
    if (value) {
      this.panel = PANEL_OPEN;
    }
    else {
      this.panel = PANEL_CLOSED;
      this.onClose();
    }
  }

  @Watch('panel')
  onPanelChange(value: 0 | null) {
    if (value === null) {
      this.isPanelOpenSync = false;
    }
    else {
      this.isPanelOpenSync = true;
      this.$nextTick().then(
        () => this.searchBarRef.focus()
      );
    }
  }

  // Auto-close the panel when selecting.
  @Watch('selectedItemSync')
  onSelectedItemSyncChange(value: unknown | null) {
    if (value !== null) {
      this.isPanelOpenSync = false;
    }
  }

  onSelectedItemClick(event: MouseEvent) {
    event.stopPropagation(); // Prevent event from bubbling to expansion panel
  }

  removeSelection() {
    this.selectedItemSync = null;
  }

  search(searchText: string) {
    this.searchText = searchText;
  }

  clearSearch() {
    this.searchText = '';
  }

  onClose() {
    if (this.searchText && this.clearSearchOnClose) {
      this.searchBarRef.clearSearchText();
    }
  }

  getItemStringValue(item: unknown): string {
    if (typeof item === 'string') {
      return item;
    }
    else if (typeof item === 'number' || typeof item === 'boolean') {
      return `${item}`;
    }
    else if (Array.isArray(item)) {
      let arrStr = '';
      item.forEach((val) => {
        arrStr += this.getItemStringValue(val);
      });
      return arrStr;
    }
    else if (item && typeof item === 'object') {
      const entries = Object.entries(item as {});
      const valuesToSearch: Array<any> = [];
      entries.forEach(entry => {
        const [key, value] = entry;
        if (!this.excludedSearchFields.includes(key)) {
          valuesToSearch.push(value);
        }
      });
      return this.getItemStringValue(valuesToSearch);
    }
    return '';
  }
}
