import { ErrorMessages } from '@/lang/errorText';
import { ApiErrorResponse } from '@/Model/errors/api/types';
import { AddEditSitePayload, GetAllSiteLocationsRequest, GetSiteLocationsRequest, GetSiteLocationsResponse, VerifiedSiteLocation, DeleteSiteLocationRequest, SiteLinkedSession, SiteLinkedToSessionErrorResponse } from '@/Model/siteLocations/types';
import { addNewLocation, addNewSiteLocationForAccount, getAllSiteLocationsForAccount, getSiteLocationsForAccount, softDeleteSiteLocation, updateSiteLocationForAccount } from '@/services/siteLocations/api';
import { ScreenText } from '@/lang/ScreenText';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import MutationNames from './mutationNames';
import GlobalMutationNames from '@/store/global/mutationNames';

const {
  SUPRESS_GLOBAL_ERROR_POPUP
} = GlobalMutationNames;

const {
  SAVE_SITE_REQUESTED,
  SAVE_SITE_SUCCEEDED,
  SAVE_SITE_FAILED,
  RESET_SAVE_SITE,
  GET_SITES_REQUESTED,
  GET_SITES_SUCCEEDED,
  GET_SITES_FAILED,
  DELETE_SITE_REQUESTED,
  DELETE_SITE_SUCCEEDED,
  DELETE_SITE_FAILED,
  RESET_DELETE_SITE
} = MutationNames;

const screenText = new ScreenText();

@Module({
  namespaced: true,
  name: 'siteLocations',
  store,
  dynamic: true
})
export class SiteLocationsModule extends VuexModule {
  isLoadingSites = false;
  isInitialLoadingSites = true;
  didSitesLoadFail = false;
  sitesLoadFailedError: ApiErrorResponse | null = null;
  sites: VerifiedSiteLocation[] = [];
  sitesTotalCount = 0;

  isSavingSites = false;
  didSitesSaveFail = false;
  siteSaveFailedError: ApiErrorResponse | null = null;

  isDeletingSite = false;
  didSiteDeleteFail = false;
  siteDeleteFailedMessage = '';
  siteLinkedSessions: SiteLinkedSession[] = [];

  errorMessages = new ErrorMessages();

  @Mutation
  [SAVE_SITE_REQUESTED]() {
    this.isSavingSites = true;
    this.didSitesSaveFail = false;
    this.siteSaveFailedError = null;
  }

  @Mutation
  [SAVE_SITE_SUCCEEDED]() {
    this.isSavingSites = false;
    this.didSitesSaveFail = false;
    this.siteSaveFailedError = null;
  }

  @Mutation
  [SAVE_SITE_FAILED](error: ApiErrorResponse) {
    this.isSavingSites = false;
    this.didSitesSaveFail = true;
    this.siteSaveFailedError = error;
  }

  @Mutation
  [RESET_SAVE_SITE]() {
    this.isSavingSites = false;
    this.didSitesSaveFail = false;
    this.siteSaveFailedError = null;
  }

  @Mutation
  [GET_SITES_REQUESTED]() {
    this.isLoadingSites = true;
    this.didSitesLoadFail = false;
    this.sitesLoadFailedError = null;
  }

  @Mutation
  [GET_SITES_SUCCEEDED](payload: GetSiteLocationsResponse) {
    this.isLoadingSites = false;
    this.didSitesLoadFail = false;
    this.sitesLoadFailedError = null;
    this.sites = payload.results;
    this.sitesTotalCount = payload.totalSetCount;
    this.isInitialLoadingSites = false;
  }

  @Mutation
  [GET_SITES_FAILED](error: ApiErrorResponse) {
    this.isLoadingSites = false;
    this.didSitesLoadFail = true;
    this.sitesLoadFailedError = error;
    this.sites = [];
    this.sitesTotalCount = 0;
    this.isInitialLoadingSites = false;
  }

  @Mutation
  [DELETE_SITE_REQUESTED]() {
    this.isDeletingSite = true;
    this.didSiteDeleteFail = false;
    this.siteDeleteFailedMessage = '';
    this.siteLinkedSessions = [];
  }

  @Mutation
  [DELETE_SITE_SUCCEEDED]() {
    this.isDeletingSite = false;
    this.didSiteDeleteFail = false;
    this.siteDeleteFailedMessage = '';
    this.siteLinkedSessions = [];
  }

  @Mutation
  [DELETE_SITE_FAILED](payload: SiteLinkedToSessionErrorResponse) {
    this.isDeletingSite = false;
    this.didSiteDeleteFail = true;
    this.siteDeleteFailedMessage = payload.message;
    this.siteLinkedSessions = payload.data;
  }

  @Mutation
  [RESET_DELETE_SITE]() {
    this.isDeletingSite = false;
    this.didSiteDeleteFail = false;
    this.siteDeleteFailedMessage = '';
    this.siteLinkedSessions = [];
  }

  @Action
  async addNewSite(newSitePayload: AddEditSitePayload) {
    const { newSite, accountId, shouldCreateLocation } = newSitePayload;

    try {
      this.SAVE_SITE_REQUESTED();

      let locationId = null;
      if (shouldCreateLocation && newSite.location) {
        const verifiedLocation = (await addNewLocation(newSite.location)).data;
        locationId = verifiedLocation.id;
      }

      await addNewSiteLocationForAccount(newSite, accountId, locationId);

      this.SAVE_SITE_SUCCEEDED();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_SITE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async updateSite(sitePayload: AddEditSitePayload) {
    const { newSite, accountId, shouldCreateLocation } = sitePayload;
    try {
      this.SAVE_SITE_REQUESTED();
      let locationId = null;
      if (shouldCreateLocation) {
        const verifiedLocation = (await addNewLocation(newSite.location)).data;
        locationId = verifiedLocation.id;
      }
      await updateSiteLocationForAccount(newSite, accountId, locationId);
      this.SAVE_SITE_SUCCEEDED();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_SITE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async resetNewSite() {
    this.RESET_SAVE_SITE();
  }

  @Action
  async getSiteLocationsForAccount(getSiteLocationsRequest: GetSiteLocationsRequest) {
    try {
      this.GET_SITES_REQUESTED();

      const response = (await getSiteLocationsForAccount(getSiteLocationsRequest)).data;

      this.GET_SITES_SUCCEEDED(response);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.GET_SITES_FAILED(apiErrorResponse);
    }
  }

  @Action
  async softDeleteSiteLocation(deleteSiteLocationRequest: DeleteSiteLocationRequest) {
    try {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, true, { root: true });

      this.DELETE_SITE_REQUESTED();

      await softDeleteSiteLocation(deleteSiteLocationRequest);

      this.DELETE_SITE_SUCCEEDED();
    }
    catch (error) {
      const errorMessage = screenText.getScreenText('ERROR_OCCURED_TRYING_TO_DELETE_SITE');

      const errorResponseObject = error as SiteLinkedToSessionErrorResponse;
      errorResponseObject.message = errorMessage;

      this.DELETE_SITE_FAILED(errorResponseObject);
    }
    finally {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, false, { root: true });
    }
  }

  @Action
  async resetDeleteSite() {
    this.RESET_DELETE_SITE();
  }

  @Action
  async getAllSiteLocationsForAccount(request: GetAllSiteLocationsRequest) {
    try {
      this.GET_SITES_REQUESTED();
      const response = await getAllSiteLocationsForAccount(request);
      this.GET_SITES_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.GET_SITES_FAILED(apiErrorResponse);
    }
  }

  get sitesWithAddress() {
    return this.sites.map(x => ({
      ...x,
      locationAddress: x.location
        ? `
        ${x.location.addressLine1},
        ${x.location.addressLine2
        ? x.location.addressLine2 + ','
        : ''}
        ${x.location.city},
        ${x.location.stateCode}
        ${x.location.zip5}
      `
        : null
    }));
  }
}
export default getModule(SiteLocationsModule);