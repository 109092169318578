

























































import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import { FormValidationError } from '@/Model/forms/types';

@Component({})
export default class TimeComboBox extends Vue {
  @PropSync('time', { type: String, required: true }) timeSync!: string;
  @PropSync('error', { validator: (v: unknown) => typeof v === 'number' || v === null, required: true }) errorSync!: FormValidationError | null;
  @PropSync('errorMessage', { type: String, required: true }) errorMessageSync!: string;
  @PropSync('open', { type: Boolean }) isOpenSync?: boolean;
  @Prop({ type: Number, default: 15 }) readonly interval!: number;
  @Prop({ type: String, default: '' }) readonly openTime!: string;
  @Prop({ type: Boolean, default: false }) readonly shouldShowLargeErrorMessage!: boolean;
  @Ref('timeInputRef') readonly timeInputRef!: HTMLSpanElement;
  @Ref('dropdownRef') readonly dropdownRef?: HTMLDivElement;
  @Ref('timeSelectList') readonly timeSelectList?: HTMLUListElement;

  readonly screenText = new ScreenText();
  readonly timeInputRegExp = /[0-9apm: ]/i;
  readonly timeInputMaxLength = 6; // Assumes no spaces (HH:MMAA) based off the `normalizeTimeInput()` func
  readonly alwaysAllowedKeys = ['ArrowLeft', 'ArrowRight', 'Home', 'End', 'Backspace', 'Delete'];
  isOpenInternal = this.isOpenSync || false;

  get isOpen() {
    const isOpen = this.isOpenInternal || !!this.isOpenSync;
    return isOpen;
  }

  get timeList(): Array<string> {
    return this.generateTimeList();
  }

  get selectText(): string {
    return this.screenText.getScreenText('TIME_COMBOBOX_SELECT_TEXT');
  }

  // This is used in the `v-html` on the input `<span>` because `v-html` and `contenteditable` can break the interpolation binding
  get displayedText(): string {
    if (this.timeSync) {
      return this.timeSync;
    }
    return this.selectText;
  }

  get hasError(): boolean {
    return this.errorSync !== null;
  }

  updated() {
    this.handleUpdate();
  }

  handleUpdate() {
    // This is for the auto-scrolling to the `openTime`
    // since we need to wait for the DOM to update and show the list
    // before attempting to scroll to the specific time.
    if (this.isOpen) {
      if (this.timeSync && this.isTimeValid(this.timeSync)) {
        this.scrollToTime(this.timeSync);
      }
      else if (this.openTime) {
        this.scrollToTime(this.openTime);
      }
    }
  }

  toggleDropdown() {
    this.isOpenInternal = !this.isOpenInternal;
    if (this.isOpenSync !== undefined) {
      this.isOpenSync = !this.isOpenSync;
    }
  }

  closeDropdown() {
    this.isOpenInternal = false;
    if (this.isOpenSync !== undefined) {
      this.isOpenSync = false;
    }
  }

  isTimeValid(time: string): boolean {
    return APP_UTILITIES.validateHhMm12Format(time) || APP_UTILITIES.validateHhMm12FormatwithoutColon(time);
  }

  normalizeTime(time: string): string {
    return time.trim().toLocaleUpperCase().replace(/(\s|&nbsp;)+/gi, '').replace(/^0([1-9])/, '$1');
  }

  onTimeInputBlur() {
    const inputInnerHTML = this.timeInputRef.innerHTML;
    const inputNormalized = this.normalizeTime(inputInnerHTML);
    if (inputNormalized && inputNormalized.toLocaleLowerCase() !== this.selectText.toLocaleLowerCase()) {
      if (this.isTimeValid(inputNormalized)) {
        const withColon = APP_UTILITIES.convertTime(inputNormalized);
        const twelveHour = APP_UTILITIES.checkTimeIs24(withColon)
          ? APP_UTILITIES.convert12hrFormateTime(withColon)
          : withColon;
        this.timeSync = twelveHour;
        this.clearError();
      }
      else {
        this.timeSync = inputInnerHTML;
        this.setError(FormValidationError.FORMAT, this.screenText.getScreenText('TIME_COMBOBOX_INVALID_FORMAT'));
      }
    }
    else {
      // TODO (REFACTOR):
      // This setting innerHTML is a temp hack because the `v-html` and `contenteditable` mess with properly re-rendering `displayedText`.
      // When we properly fix/refactor this component to use proper inputs, this (along with all the direct HTML manipulation) should go away.
      if (!this.timeSync && inputInnerHTML !== this.selectText) {
        this.timeInputRef.innerHTML = this.selectText;
      }
      this.timeSync = '';
      this.clearError();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.timeInputRef.blur();
      if (this.isOpen) {
        this.closeDropdown();
      }
      event.preventDefault();
    }
    else {
      const normalizedText = this.normalizeTime(this.timeInputRef.innerHTML);
      const isAlwaysAllowedKey = this.alwaysAllowedKeys.includes(event.key);
      if (!isAlwaysAllowedKey) {
        const exceedsMaxLength = normalizedText.length > this.timeInputMaxLength;
        const isValid = this.timeInputRegExp.test(event.key);
        if (exceedsMaxLength) {
          event.preventDefault();
        }
        else if (!isValid) {
          event.preventDefault();
        }
      }
    }
  }

  onSelectTime(time: string) {
    this.timeSync = time;
    this.clearError();
  }

  generateTimeList(): Array<string> {
    const ap = ['AM', 'PM'];
    const timeList: Array<string> = [];
    let intervalTime = 0;
    for (let i = 0; intervalTime < 24 * 60; i++) {
      const hh = Math.floor(intervalTime / 60);
      const mm = (intervalTime % 60);
      let hr;
      if (hh % 12 === 0) {
        hr = 12;
      }
      else {
        hr = hh % 12;
      }
      timeList[i] = ('' + (hr)).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
      intervalTime = intervalTime + this.interval;
    }
    return timeList;
  }

  onClickOutside() {
    this.closeDropdown();
  }

  getRefNameForTimeValue(time: string): string {
    return `time${this.normalizeTime(time).replace(/[\s:]/g, '')}`;
  }

  scrollToTime(time: string) {
    const refName = this.getRefNameForTimeValue(time);
    const ref = this.$refs[refName];
    if (Array.isArray(ref) && ref[0] instanceof Element && this.timeSelectList) {
      const refTop = (ref[0] as HTMLElement).offsetTop;
      this.timeSelectList.scrollTop = refTop;
    }
  }

  setError(error: FormValidationError, errorMessage: string) {
    this.errorSync = error;
    this.errorMessageSync = errorMessage;
  }

  clearError() {
    this.errorSync = null;
    this.errorMessageSync = '';
  }
}
